import React from 'react';
import { useIntl, navigate } from 'gatsby-plugin-intl';

import SEO from '../../components/Seo';

import { Container, Title, Description, HomeButton } from './styles';

export default function NotFoundPage() {
  const intl = useIntl();

  const handleClick = () => {
    navigate('/');
  };

  return (
    <>
      <SEO title={intl.formatMessage({ id: 'pages.notFound.title' })} />

      <Container>
        <Title>404</Title>

        <Description>
          {intl.formatMessage({ id: 'pages.notFound.description' })}
        </Description>

        <HomeButton onClick={handleClick}>
          {intl.formatMessage({ id: 'components.button.home' })}
        </HomeButton>
      </Container>
    </>
  );
}
