import styled from 'styled-components';

import Button from '../../components/Button';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

export const Title = styled.h1`
  font-size: 4rem;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.color};
`;

export const Description = styled.p`
  margin-bottom: 20px;
`;

export const HomeButton = styled(Button)``;
